import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

import {Link} from "react-scroll";

//GA
import ReactGA from "react-ga";

//imagenes
import img_logo from "../images/logo.svg";
import img_logo_TT from "../images/toma_turno_logo.svg";
import GP from "../images/Tiendas/Google_Play.svg";
import AS from "../images/Tiendas/App_Store.svg";

export function Header() {
  let history = useHistory();
  
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  const ContenidoH = styled.header`
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
    background-color: white;
    display: block;

    & div nav img {
      height: 5vh;
    }

    position: relative;
    z-index: 99;
  `;

  function seleccionar(pathname) {
    $(".nav-item .nav-link").css("color", "var(--color-gris-3)");
    switch (pathname) {
      case "/":
        $(".nav-item:nth-child(1) .nav-link").css(
          "color",
          "var(--color-naranja)"
        );
        break;
      case "/negocios":
        $(".nav-item:nth-child(2) .nav-link").css(
          "color",
          "var(--color-naranja)"
        );
        break;
      default:
        $(".nav-item .nav-link").css("color", "var(--color-gris-3)");
        break;
    }
  }

  useEffect(() => {
    $(".nav-item .nav-link").css("color", "var(--color-gris-3)");
    switch (history.location.pathname) {
      case "/":
        $(".nav-item:nth-child(1) .nav-link").css(
          "color",
          "var(--color-naranja)"
        );
        break;
      case "/negocios":
        $(".nav-item:nth-child(2) .nav-link").css(
          "color",
          "var(--color-naranja)"
        );
        break;
      default:
        $(".nav-item .nav-link").css("color", "var(--color-gris-3)");
        break;
    }
  }, [history]);

  return (
    
    <ContenidoH>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <Link
            to="/"
            className="nav-link text-uppercase"
            style={{
              textDecoration: "none",
              color: "var(--color-gris-3)",
              fontWeight: "500",
            }}
            onClick={() => {
              seleccionar("/");
              ReactGA.event({
                category: "Usuario",
                action: "click",
                label: "Clientes",
              });
            }}
          >
            <img src={img_logo} alt="" height="100%" className="mt-2 mb-2" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link
                 to="app"
                 activeClass="active" 
                 spy={true} 
                 smooth={true}
                  className="nav-link text-uppercase"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-gris-3)",
                    fontWeight: "500",
                    cursor: "pointer", 
                  }}
                  activeStyle={{
                    color: "red", 
                    fontWeight: "bold", 
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "red"; 
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "var(--color-gris-3)"; 
                  }}
                
                >
                  <span className="text-wrap">App</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="consola"
                  activeClass="active" 
                 spy={true} 
                 smooth={true}
                  className="nav-link text-uppercase"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-gris-3)",
                    fontWeight: "500",
                    cursor: "pointer", 
                  }} 
                  activeStyle={{
                    color: "red", 
                    fontWeight: "bold", 
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "red"; 
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "var(--color-gris-3)"; 
                  }}
                
                >
                  <span className="text-wrap">Consola</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="Bene"
                  activeClass="active" 
                 spy={true} 
                 smooth={true}
                  className="nav-link text-uppercase"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-gris-3)",
                    fontWeight: "500",
                    cursor: "pointer", 
                  }}
                  activeStyle={{
                    color: "red",  
                    fontWeight: "bold", 
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "red"; 
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "var(--color-gris-3)"; 
                  }}
                >
                  <span className="text-wrap">Beneficios</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="hard"
                  activeClass="active" 
                 spy={true} 
                 smooth={true}
                  className="nav-link text-uppercase"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-gris-3)",
                    fontWeight: "500",
                    cursor: "pointer", 
                  }}
                  activeStyle={{
                    color: "red",  
                    fontWeight: "bold", 
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "red"; 
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "var(--color-gris-3)"; 
                  }}
                >
                  <span className="text-wrap">Hardware</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contacta"
                  activeClass="active" 
                 spy={true} 
                 smooth={true}
                  className="nav-link text-uppercase"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-gris-3)",
                    fontWeight: "500",
                    cursor: "pointer", 
                  }}
                  activeStyle={{
                    color: "red",  
                    fontWeight: "bold", 
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "red"; 
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "var(--color-gris-3)"; 
                  }}
                >
                  <span className="text-wrap">Contacto</span>
                </Link>
              </li>

              
            </ul>
          </div>
        </nav>
      </div>
    </ContenidoH>
  );
}

export function Footer() {
  const ContenidoH = styled.footer`
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);

    & #logo_TT {
      height: 12vh;
    }

    position: relative;
    z-index: 99;
  `;

  const ImgTiendas = styled.img`
    @media (max-width: 991.98px) {
      width: 40%;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: 35%;
    }

    @media (min-width: 1200px) {
      width: 30%;
    }
  `;

  return (
    <ContenidoH>
      <div className="container-fluid texto-gris">
        <div className="row py-4 border-bottom">
          <div className="col-12 col-md-4 text-center">
            <img className="mb-4" id="logo_TT" alt="" src={img_logo_TT} />
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex flex-column">
              <div className="mb-4 texto-naranja h6">Dirección</div>
              <div
                className="p-2"
                style={{ fontSize: "0.9rem", fontWeight: "600" }}
              >
                Av. Insurgentes Sur 1802, piso 3, Col. Florida, Alcaldia Alvaro
                Obregón, C.P. 01030, México, CDMX
              </div>
              <div
                className="p-2"
                style={{ fontSize: "0.9rem", fontWeight: "600" }}
              >
                Teléfono celular: 5579213143 y 5539991465
              </div>
              <div
                className="p-2"
                style={{ fontSize: "0.9rem", fontWeight: "600" }}
              >
                Telefono oficina: 5565451095
              </div>
             
              <div
                className="p-2"
                style={{ fontSize: "0.9rem", fontWeight: "600" }}
              >
                e-mail: contacto@toma-turno.mx
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex flex-column">
              <div className="mb-4 texto-naranja h6">Siguenos</div>
              <div className="p-2 text-center">
                <div className="d-flex flex-row">
                  <div className="p-2">
            
                  </div>
                  <div className="p-2">
                    <FaLinkedin
                      style={{ color: "#1778f2", fontSize: "2.2rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 texto-naranja h6"></div>
              <div className="p-2">
          
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div
            className="col-12 text-center texto-gris"
            style={{ fontSize: "0.9rem", fontWeight: "600" }}
          >
            © 2021 TomaTurno<br></br>
            <a href="/policy">
          POLITICA DE PRIVACIDAD
      </a>
          </div>
        </div>
      </div>
    </ContenidoH>
  );
}
