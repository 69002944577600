import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Error() {
  let history = useHistory();

  useEffect(() => {
    history.push("/");
  });

  return <></>;
}
