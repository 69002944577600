import React, { useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import $ from "jquery";
import { Link, animateScroll as scroll } from "react-scroll";


//componentes

//imagenes
import carrousel1 from "../images/portadas/Carrusel_01.png";
import carrousel2 from "../images/portadas/Carrusel_02.png";
import carrousel3 from "../images/portadas/Carrusel_03.png";
import carrousel4 from "../images/portadas/Carrusel_04.png";
import carrousel5 from "../images/portadas/Carrusel_05.png";
import carrousel6 from "../images/portadas/Carrusel_06.png";
import fila from "../images/portadas/Fila.png"
import una_app from "../images/una-app.png";
import tuerca from "../images/Soluciona/Automatiza.svg";
import equis from "../images/Soluciona/Elimina.svg";
import megafono from "../images/Soluciona/Promueve.svg";
import corazon from "../images/Soluciona/Satisfaccion.svg";
import mall from "../images/bannersuscripcion.png";
import celular_contacto from "../images/celular-contacto.png";
import tiempo from "../images/Soluciona/1Automatiza.svg";
import servicio2 from "../images/Soluciona/2Servicios.svg"
import satisfaccion from "../images/Soluciona/3Satisfaccion.svg"
import informacion from "../images/Soluciona/informacion.svg"
import agendas from "../images/Soluciona/4Agendas.svg"
import nofilas from "../images/Soluciona/5No filas.svg"
import tel1 from "../images/Como_funciona/como_funciona_01.png";
import tel2 from "../images/Como_funciona/como_funciona_02.png";
import tel3 from "../images/Como_funciona/como_funciona_03.png";
import telefonos from "../images/295.png"
import GP from "../images/Tiendas/Google_Play.svg";
import AS from "../images/Tiendas/App_Store.svg";
import WEB from "../images/Tiendas/web.svg"
import tablero from "../images/monitor-pc.png"
import tvtorre from "../images/tvtorre.png"
import contacto from "../images/contacto.png"

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);

export default function Negocios() {
  const Portada = styled.div`
    display: block;
    height: calc(100vh - 3rem);
    width: 100%;

    & div {
      height: 100%;
    }

    & #img-1 {
      height: 100%;
    

      background-image: url(${fila});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    & #img-2 {
      height: 100%;
     

      background-image: url(${fila});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  const Display = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: -100%;
    z-index: 1;
  `;

  const ImgTiendas = styled.img`
  @media (max-width: 991.98px) {
    width: 40%;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 35%;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;
  const Cuadro = styled.div`
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;

    @media (max-width: 991.98px) {
      .contenido {
        padding: 1rem;
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      .contenido {
        padding: 2rem;
      }
    }

    @media (min-width: 1200px) {
      .contenido {
        padding: 3rem;
      }
    }
  `;

  const Imagen1 = styled.div`
    background-image: url(${contacto});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const Imagen2 = styled.div`
  background-image: url(${celular_contacto});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

  const Promocion = styled.div`
    width: 100%;
    background-color: white;
    position: relative;
    top: -2rem;
  `;

  const Mensaje = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    margin: 0.5;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 99;
    display: none;
    padding: 2rem;

    div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  `;

  function formulario(event) {
    const url =
      "https://r1tbpzzkgh.execute-api.us-west-2.amazonaws.com/production/mensajes";

    const data = {
      nombre: $("#nombre").val(),
      numero: $("#telefono").val(),
      email: $("#correo").val(),
      mensaje: $("#mensaje").val(),
    };

    fetch(url, {
      method: "POST",
      crossDomain: true,
      body: JSON.stringify(data),
      dataType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => console.log(res.json()))
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log("Success:", response);
        $("#con-msg").fadeIn(500);
      });

    event.preventDefault();
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <div id="app" className="bg-white">
        <div className="container">
          <div className="row py-5">

            <div className="col-12">
              <div className="d-flex flex-column">
                <div className="px-5 mb-4 texto-naranja font-weight-bold"  >
                  <span className="text-justify" style={{ fontWeight: "600", fontSize: "2.5rem", alignItems: "center" }}>
                    POLITICA DE PRIVACIDAD
                  </span></div>
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span className="text-justify" style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                      <ol >
                        <li>Acerca de la política</li>
                        <br></br>
                        <span>Esta política describe cómo usamos y procesamos tus datos personales en “SUPAADA CONSULTING S.A DE C.V.”, denominado a partir de ahora como “Toma Turno Corporativo.
                          Ocasionalmente  es posible que desarrollemos nuevos servicios u ofrezcamos servicios adicionales.
                          También estarán sujetos a esta Política, a menos que se indique lo contrario cuando los presentemos.
                        </span>
                        <br></br><br></br>
                        <li>Información que se recopila y procesa </li><br></br>
                        <span>La información que nos proporciona como:</span>
                        <br></br>
                        <ul>
                          <li>Registro de cuenta</li>
                          <li>Registros y envíos de otros formularios</li>
                          <li>Interacciones con “Toma Turno Corporativo"</li>
                        </ul><br></br>
                        <span>Información que recopilamos:</span>
                        <ul>
                          <li>Datos de usuario: Trata de datos personales que necesitamos recolectar de ti para regístrate dentro del sistema “Toma Turno Corporativo” y poder hacer uso de la aplicación “Toma Turno Corporativo”. El tipo de datos que recolectamos puede incluir:
                          </li>
                          <ul>
                            <li>Nombre de perfil</li>
                            <li>Dirección de correo electrónico</li>
                            <li>Número de teléfono</li>
                            <li>Ubicación por Geolocalización</li>
                          </ul>
                        </ul>

                        <br></br>
                        <span>Algunos de estos datos pueden ser recolectados durante el registro de la cuenta o directamente desde tu dispositivo.</span><br></br><br></br>
                        <ul>
                          <li>	Información de dispositivos móviles: Cuando elige permitirlo, algunas de nuestras aplicaciones móviles tienen acceso a la cámara, historial de llamadas, información de contacto,
                            biblioteca de fotos y otra información almacenada en su dispositivo móvil. Nuestras aplicaciones requieren dicho acceso para proporcionar sus servicios.
                            De manera similar, cuando elige proporcionar acceso, la información basada en la ubicación también se recopila con fines que incluyen, entre otros, la ubicación de contactos cercanos o la configuración de recordatorios basados en la ubicación.
                            Puede deshabilitar el acceso de las aplicaciones móviles a esta información en cualquier momento editando la configuración en su dispositivo móvil.
                            Los datos almacenados en su dispositivo móvil y la información de su ubicación a la que tienen acceso las aplicaciones móviles se utilizarán en el contexto de la aplicación móvil
                          </li><br></br>
                        </ul>
                        <span>Durante el uso de la aplicación “Toma Turno Corporativo” puede recolectar los siguientes datos:</span>
                        <br></br>
                        <ul>
                          <li>Datos de uso:  Datos personales que se recopilan por medio del uso de los servicios de “Toma Turno Corporativo”, algunos de estos datos pueden incluir:</li>
                        </ul>
                        <ol>
                          <li>Las acciones que lleves a cabo dentro de “Toma Turno Corporativo” tales como:</li>
                          <ul>
                            <li>Registro dentro del sistema de "Toma Turno Corporativo"</li>
                            <li>Seleccion de sucursal</li>
                            <li>Proceso de cita</li>
                            <li>Proceso de turno</li>
                            <li>Eleccion de servicio de proveedor de movilidad</li>
                            <li>Historicos</li>
                          </ul>
                          <li>Tus datos técnicos como:</li>
                          <ul>
                            <li>Geolocalización</li>
                            <li>ID de dispositivo</li>
                            <li>tipo de conexión de red (por ejemplo, wifi, 4G, LTE, Bluetooth)</li>
                            <li>idioma</li>
                            <li>información que permite la administración de derechos digitales</li>
                            <li>sistema operativo</li>
                            <li>versión de la aplicación “Toma Turno Corporativo”</li>
                          </ul>
                        </ol>
                        <br></br>
                        <li>Finalidades de la Información</li><br></br>
                        <span>La finalidad con la que utilizaremos esta información es: </span><br></br><br></br>
                        <ul>
                          <li>Para comunicarnos sobre cambios en la política de privacidad o términos y condiciones de estos.</li>
                          <li>Para configurar y mantener su cuenta, y para hacer todas las demás cosas necesarias para proporcionar nuestros servicios.</li>
                          <li>Para comprender cómo los usuarios usan nuestros productos.</li>
                          <li>Para brindar atención al cliente y analizar nuestro servicio.</li>
                        </ul>
                        <br></br>
                        <li>Retención y eliminación de datos:</li><br></br>
                        <span>Conservamos tus datos personales solo el tiempo necesario para proporcionarte el Servicio de “Toma Turno Corporativo”, y para los fines comerciales legítimos y esenciales de “Toma Turno Corporativo”.
                          Contamos con sistemas administrativos, técnicos y físicos apropiados para evitar el acceso, uso, modificación, divulgación o destrucción no autorizados de la información que nos confía.
                          Si cierras o solicitas que cerremos tu cuenta, eliminaremos tus datos personales o los volveremos anónimos para que ya no te identifiquen, sin embargo,
                          es posible que la ley nos exija conservar o divulgar su información personal y datos de servicio para cumplir con cualquier ley, regulación, proceso legal o solicitud gubernamental aplicable, incluso para cumplir con los requisitos de seguridad nacional.
                        </span>
                        <br></br><br></br>
                        <li>Leyes y reglamentos aplicables</li><br></br>
                        <span>Para el ejercicio de los derechos de Acceso, Rectificación, Cancelación y Oposición (“Derechos ARCO”) deberá presentar la solicitud correspondiente a través del correo contacto@toma-turno.mx</span> <br></br><br></br>
                        <li>Cambios a la política</li><br></br>
                        <span>La política presente está dispuesta a cambios y modificaciones. Al momento de realizar cambios en esta política, te proporcionaremos un aviso con al menos (20) días de anticipación por correo electrónico o 
                          cualquier otro medio que haya registrado. Si cree que la Política de privacidad actualizada afecta sus derechos con respecto al uso de nuestros servicios o productos, puede rescindir su uso enviándonos un correo 
                          electrónico dentro de los (20) días. Su uso continuado después de la fecha de vigencia de los cambios a la Política de privacidad se considerará como su aceptación de la Política de privacidad modificada
                        </span><br></br><br></br>
                        <li>Contacto</li><br></br>
                        <span>Si desea obtener información adicional acerca de la presente política de privacidad envía un correo electrónico a contacto@toma-turno.mx
                        </span>
                      </ol>
                    </span>
                    <br></br>
                    <a href="/" style={{ fontWeight: "600", fontSize: "1.1rem",alignContent:"center" }}>INICIO</a>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span className="text-justify" style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                      <div className="p-2">

                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>









    </>
  );
}
