import React, { useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

//componentes

//imagenes
import portada from "../images/portadas/Portada_Clientes.png";
import tel1 from "../images/Como_funciona/como_funciona_01.png";
import tel2 from "../images/Como_funciona/como_funciona_02.png";
import tel3 from "../images/Como_funciona/como_funciona_03.png";
import GP from "../images/Tiendas/Google_Play.svg";
import AS from "../images/Tiendas/App_Store.svg";


ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
ReactGA.pageview("/");
console.log(window.location.pathname + window.location.search);


export default function Negocios() {
  const Portada = styled.div`
    background-image: url(${portada});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 4rem);
    width: 100%;

    z-index: 1;
  `;

  const Display = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
    z-index: 99;
    transform: translateY(-25%);
  `;

  const Cuadro = styled.div`
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;

    @media (max-width: 991.98px) {
      .contenido {
        padding: 1rem;
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      .contenido {
        padding: 2rem;
      }
    }

    @media (min-width: 1200px) {
      .contenido {
        padding: 3rem;
      }
    }
  `;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <Portada>
        <Display>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 h-auto align-self-center">
                <Cuadro>
                  <div className="d-flex flex-column contenido">
                    <div className="p-3 h1">¡Ya no hagas filas!</div>
                    <div className="px-3 pb-3">
                      Todas tus citas desde una sola aplicación.
                    </div>
                    <div
                      className="p-3 text-center"
                      style={{ fontSize: "0.8rem" }}
                    >
                      Próximamente en:
                    </div>
                    <div
                      className="mx-4 text-right"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <img
                        src={GP}
                        alt=""
                        width="30%"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          ReactGA.event({
                            category: "Usuario",
                            action: "click",
                            label: "Google Play",
                          })
                        }
                      />{" "}
                      <img
                        src={AS}
                        alt=""
                        width="30%"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          ReactGA.event({
                            category: "Usuario",
                            action: "click",
                            label: "App Store",
                          })
                        }
                      />
                    </div>
                  </div>
                </Cuadro>
              </div>
            </div>
          </div>
        </Display>
      </Portada>
      <div
        style={{
          background:
            "linear-gradient(to bottom, var(--color-gris-2) 60%, var(--color-gris-1) 40%)",
        }}
      >
        <div className="container">
          <div className="row py-4 justify-content-center">
            <div className="col-12 mt-5 mb-5 text-center">
              <span className="texto-naranja h3">¿Cómo funciona?</span>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
              <div className="d-flex flex-column">
                <div>
                  <img src={tel1} alt="" style={{ height: "40vh" }} />
                </div>
                <div className="mt-2 texto-gris" style={{ fontSize: "1.1rem" }}>
                  Busca lo que necesitas dentro del amplio catálogo de negocios.
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
              <div className="d-flex flex-column">
                <div>
                  <img src={tel2} alt="" style={{ height: "40vh" }} />
                </div>
                <div className="mt-2 texto-gris" style={{ fontSize: "1.1rem" }}>
                  Haz tus citas en tus lugares favoritos, o compara la oferta
                  por calificación, precio y distancia.
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center mb-3">
              <div className="d-flex flex-column">
                <div>
                  <img src={tel3} alt="" style={{ height: "40vh" }} />
                </div>
                <div className="mt-2 texto-gris" style={{ fontSize: "1.1rem" }}>
                  Realiza tu cita y acude con tu código QR.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
