import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./style/global.css";

//componentes
import { Header, Footer } from "./components/Plantilla";
import Negocios from "./pages/Negocios";
import Clientes from "./pages/Clientes";
import Error from "./pages/Error";
import Privacidad from "./pages/Privacidad";

function App() {
  return (
    <Router>
      <Header />
      
      <Switch>
        <Route exact path="/" component={Negocios} />
        <Route exact path="/policy" component={Privacidad} />
        <Route path="*" component={Error} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
