import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import ReactGA from "react-ga";
import $ from "jquery";
import { Link, animateScroll as scroll } from "react-scroll";

//componentes

//imagenes
import carrousel1 from "../images/portadas/Carrusel_01.png";
import carrousel2 from "../images/portadas/Carrusel_02.png";
import carrousel3 from "../images/portadas/Carrusel_03.png";
import carrousel4 from "../images/portadas/Carrusel_04.png";
import carrousel5 from "../images/portadas/Carrusel_05.png";
import carrousel6 from "../images/portadas/Carrusel_06.png";
import fila from "../images/portadas/Fila.png";
import una_app from "../images/una-app.png";
import tuerca from "../images/Soluciona/Automatiza.svg";
import equis from "../images/Soluciona/Elimina.svg";
import megafono from "../images/Soluciona/Promueve.svg";
import corazon from "../images/Soluciona/Satisfaccion.svg";
import mall from "../images/bannersuscripcion.png";
import celular_contacto from "../images/celular-contacto.png";
import tiempo from "../images/Soluciona/1Automatiza.svg";
import servicio2 from "../images/Soluciona/2Servicios.svg";
import satisfaccion from "../images/Soluciona/3Satisfaccion.svg";
import informacion from "../images/Soluciona/informacion.svg";
import agendas from "../images/Soluciona/4Agendas.svg";
import nofilas from "../images/Soluciona/5No filas.svg";
import tel1 from "../images/Como_funciona/como_funciona_01.png";
import tel2 from "../images/Como_funciona/como_funciona_02.png";
import tel3 from "../images/Como_funciona/como_funciona_03.png";
import telefonos from "../images/295.png";
import GP from "../images/Tiendas/Google_Play.svg";
import AS from "../images/Tiendas/App_Store.svg";
import WEB from "../images/Tiendas/web.svg";
import tablero from "../images/monitor-pc.png";
import tvtorre from "../images/tvtorre.png";
import contacto from "../images/contacto.png";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
ReactGA.pageview("/");

export default function Negocios() {
  const Portada = styled.div`
    display: block;
    height: calc(100vh - 3rem);
    width: 100%;

    & div {
      height: 100%;
    }

    & #img-1 {
      height: 100%;

      background-image: url(${fila});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    & #img-2 {
      height: 100%;

      background-image: url(${fila});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  const Display = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: -100%;
    z-index: 1;
  `;

  const ImgTiendas = styled.img`
    @media (max-width: 991.98px) {
      width: 40%;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: 35%;
    }

    @media (min-width: 1200px) {
      width: 30%;
    }
  `;
  const Cuadro = styled.div`
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;

    @media (max-width: 991.98px) {
      .contenido {
        padding: 1rem;
      }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      .contenido {
        padding: 2rem;
      }
    }

    @media (min-width: 1200px) {
      .contenido {
        padding: 3rem;
      }
    }
  `;

  const urlVideo =
    "https://tomaturno-corporativo-landing-page.s3.us-east-2.amazonaws.com/videos/TOMATURNOV10+MASTER+(2).mp4";

  const Imagen1 = styled.div`
    background-image: url(${contacto});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const Imagen2 = styled.div`
    background-image: url(${celular_contacto});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `;

  const Promocion = styled.div`
    width: 100%;
    background-color: white;
    position: relative;
    top: -2rem;
  `;

  const Mensaje = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    margin: 0.5;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 99;
    display: none;
    padding: 2rem;

    div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  `;

  function scrollToDiv() {
    const div = document.getElementById("contacta");
    div.scrollIntoView({ behavior: "smooth" });
  }

  function formulario(event) {
    const url =
      "https://r1tbpzzkgh.execute-api.us-west-2.amazonaws.com/production/mensajes";

    const data = {
      nombre: $("#nombre").val(),
      numero: $("#telefono").val(),
      email: $("#correo").val(),
      empresa: $("#empresa").val(),
      mensaje: $("#mensaje").val(),
    };
    console.log(data);

    fetch(url, {
      method: "POST",
      crossDomain: true,
      body: JSON.stringify(data),
      dataType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => console.log(res.json()))
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log("Success:", response);
        $("#con-msg").fadeIn(500);
      });

    event.preventDefault();
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <Portada className="">
        <div className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="d-block h-100" id="img-1"></div>
            </div>
            <div className="carousel-item">
              <div className="d-block h-100" id="img-2"></div>
            </div>
          </div>
        </div>
        <Display>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 h-auto align-self-center">
                <Cuadro>
                  <div className="d-flex flex-column contenido">
                    <div className="p-3 h1">
                      ¡Termina con las filas en tu sucursal!
                    </div>
                    <div className="px-3 pb-3"></div>
                    <div className="p-3">
                      <button
                        type="submit"
                        onClick={scrollToDiv}
                        className="btn btn-danger bg-naranja"
                        style={{ width: "60%" }}
                      >
                        Me interesa
                      </button>
                    </div>
                  </div>
                </Cuadro>
              </div>
            </div>
          </div>
        </Display>
      </Portada>

      <div id="media" className="bg-gris-1">
        <div className="container">
          <div className="row p-5">
            <div
              className="contenedor-video"
            >
              <ReactPlayer
                url={urlVideo}
                controls
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="app" className="bg-white">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-6 align-self-center">
              <img src={telefonos} alt="" width="100%" />
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <div className="px-5 mb-4 texto-naranja font-weight-bold">
                  <span
                    className="text-justify"
                    style={{ fontWeight: "600", fontSize: "2.5rem" }}
                  >
                    Toma Turno es un sistema que crea filas virtuales.
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    >
                      Con nuestra APP, tu cliente toma su turno o su cita desde
                      su celular, y hace fila desde su sofá, el parque o la
                      oficina.
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    >
                      <div className="p-2">
                        <ImgTiendas
                          src={GP}
                          alt=""
                          width="35%"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            ReactGA.event({
                              category: "Usuario",
                              action: "click",
                              label: "Google Play",
                            })
                          }
                        />{" "}
                        <ImgTiendas
                          src={AS}
                          alt=""
                          width="35%"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            ReactGA.event({
                              category: "Usuario",
                              action: "click",
                              label: "App Store",
                            })
                          }
                        />{" "}
                        <ImgTiendas
                          src={WEB}
                          alt=""
                          width="35%"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            ReactGA.event({
                              category: "Usuario",
                              action: "click",
                              label: "WEB",
                            })
                          }
                        />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="consola" className="bg-gris-1">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <div className="px-5 mb-4 texto-naranja font-weight-bold">
                  <br />
                  <br />
                  <span
                    className="text-justify"
                    style={{ fontWeight: "600", fontSize: "2.5rem" }}
                  >
                    Toma Turno es un sistema ajustado a tus necesidades.
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    >
                      Pone a tu disposición tableros con estadísticas, gestor de
                      sucursales y ventanillas, y control de citas/turnos. Cada
                      plataforma es personalizada en base a las necesidades de
                      cada negocio.
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 align-self-center">
              <img src={tablero} alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>

      <div id="Bene" className="bg-gris-1">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-5 text-center">
              <span className="texto-naranja h3">
                ¿Qué logras con Toma Turno?
              </span>
            </div>
          </div>
          <div className="row texto-gris">
            <div className="col-12 col-md-6 text-center">
              <div className="d-flex flex-column px-5">
                <div className="mb-3">
                  <img src={nofilas} alt="" style={{ height: "10vh" }} />
                </div>
                <div className="mb-2 h5" style={{ fontWeight: "bold" }}>
                  ELIMINA LAS FILAS
                </div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  Haz que tus clientes esperen su turno en la comodidad de su
                  casa u oficina, sin que pierdan su tiempo.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 text-center">
              <div className="d-flex flex-column px-5">
                <div className="mb-3">
                  <img src={servicio2} alt="" style={{ height: "10vh" }} />
                </div>
                <div className="mb-2 h5">PROMOCIÓN CONTINUA</div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  Toma Turno te permite promocionar tus servicios constantemente
                  con tus clientes.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 text-center">
              <div className="d-flex flex-column px-5">
                <div className="mb-3">
                  <img src={informacion} alt="" style={{ height: "10vh" }} />
                </div>
                <div className="mb-2 h5">GENERA INFORMACIÓN</div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  Recupera información del comportamiento de tu cliente para
                  ayudarte a tomar decisiones que mejoren tu servicio.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 text-center">
              <div className="d-flex flex-column px-5">
                <div className="mb-3">
                  <img src={satisfaccion} alt="" style={{ height: "10vh" }} />
                </div>
                <div className="mb-2 h5" style={{ fontWeight: "bold" }}>
                  MEJORA TU SERVICIO
                </div>
                <div className="mb-2" style={{ fontWeight: "500" }}>
                  Optimiza tu servicio de atención e incrementa el nivel de
                  satisfacción de tus clientes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="hard" className="bg-secondary">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <br />
                <br />
                <br />
                <br />

                <div className="px-5 mb-4 text-light font-weight-bold">
                  <span
                    className="text-justify"
                    style={{ fontWeight: "600", fontSize: "2.5rem" }}
                  >
                    COMPLEMENTA TU SERVICIO.
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <br />
                  <div className="col-12  align-self-center text-light">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    >
                      Aquellos de tus clientes que no cuenten con la APP, pueden
                      tomar un turno directamente en tu sucursal mediante
                      nuestro quiosco, que también recupera información para tu
                      sistema.
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="d-flex flex-column">
                  <div className="col-12  align-self-center texto-gris">
                    <span
                      className="text-justify"
                      style={{ fontWeight: "600", fontSize: "1.1rem" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 align-self-center">
              <img src={tvtorre} alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>

      <div id="contacta" className="bg-white">
        <Imagen1>
          <div className="container">
            <div className="row py-5">
              <div className="col-12 col-md-6 align-self-center"></div>
              <div className="col-12 col-md-6">
                <div className="d-flex flex-column">
                  <div className="p-0">
                    <form
                      className="bg-gris-2 p-4 text-center"
                      onSubmit={(event) => formulario(event)}
                      style={{ position: "relative" }}
                    >
                      <div className="font-weight-bold">CONTÁCTANOS,</div>
                      <div className="px-5 mb-4 font-weight-bold">
                        SOLICITA TU DEMO
                      </div>
                      <div className="form-group">
                        <input
                          id="nombre"
                          className="form-control"
                          placeholder="Nombre"
                          type="text"
                          maxLength="20"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="empresa"
                          className="form-control"
                          placeholder="Empresa"
                          type="text"
                          maxLength="20"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="telefono"
                          className="form-control"
                          placeholder="Numero telefónico"
                          type="text"
                          maxLength="10"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="correo"
                          className="form-control"
                          placeholder="E-mail"
                          type="email"
                          maxLength="50"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          id="mensaje"
                          className="form-control"
                          placeholder="Mensaje"
                          type="text"
                          rows="5"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-danger bg-naranja"
                        style={{ width: "50%" }}
                      >
                        Enviar
                      </button>
                      <Mensaje id="con-msg">
                        <div id="msg">
                          <h2>
                            Gracias, nos comunicaremos lo más pronto posible
                            contigo.
                          </h2>
                        </div>
                      </Mensaje>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Imagen1>
      </div>
    </>
  );
}
